import {Skin, Spine} from '@pixi-spine/runtime-4.1'
import {Container, Graphics, Text, TextMetrics, TextStyle} from 'pixi.js'
import {Weapon} from './enums'
import {PlayerSchema} from './server/schema'
import {fireParticles} from './special-effects'
import {gameState} from './state'
import {computeVelocityToReach, drawProgressBar, getWidthRatio, segmentAngle} from './utils'

export const initPlayer = (playerSchema: PlayerSchema) => {
  if (!gameState.viewport || !gameState.resources) return

  const playerSpine = new Spine(gameState.resources.player.spineData)

  const skinCombined = new Skin('combined')

  if (playerSchema.cosmetics.outfits) {
    const outfits = playerSpine.skeleton.data.findSkin(`OUTFITS/${playerSchema.cosmetics.outfits}`)
    if (outfits) skinCombined.addSkin(outfits)
  } else {
    if (playerSchema.cosmetics.eyes) {
      const eyes = playerSpine.skeleton.data.findSkin(`EYES/${playerSchema.cosmetics.eyes}`)
      if (eyes) skinCombined.addSkin(eyes)
    }

    if (playerSchema.cosmetics.head) {
      const head = playerSpine.skeleton.data.findSkin(`HEAD/${playerSchema.cosmetics.head}`)
      if (head) skinCombined.addSkin(head)
    }

    if (playerSchema.cosmetics.top) {
      const top = playerSpine.skeleton.data.findSkin(`TOP/${playerSchema.cosmetics.top}`)
      if (top) skinCombined.addSkin(top)
    }

    if (playerSchema.cosmetics.mouth) {
      const mouth = playerSpine.skeleton.data.findSkin(`MOUTH/${playerSchema.cosmetics.mouth}`)
      if (mouth) skinCombined.addSkin(mouth)
    }
  }

  if (playerSchema.cosmetics.fur) {
    const fur = playerSpine.skeleton.data.findSkin(`FUR/${playerSchema.cosmetics.fur ?? 'ORANGE'}`)

    if (playerSchema.cosmetics.outfits) {
      fur.removeAttachment(10, 'FUR/DARK/LEFT HAND')
      fur.removeAttachment(11, 'FUR/DARK/LEFT HAND')

      if (['ANDY', 'BRYAN', 'JUSTIN'].includes(playerSchema.cosmetics.outfits)) {
        fur.removeAttachment(16, 'Pink/Pink_back ear')
        fur.removeAttachment(20, 'Pink/Pink_front ear')
      }
    }

    if (fur) skinCombined.addSkin(fur)
  }

  playerSpine.skeleton.setSkin(skinCombined)

  const ratio = Math.min(playerSchema.width / playerSpine.width, playerSchema.height / playerSpine.height)

  playerSpine.scale.set(ratio, ratio)

  playerSpine.position.set(playerSchema.x + playerSchema.width / 2, playerSchema.y + playerSpine.height)

  playerSpine.state.setAnimation(0, 'IDLE', true)

  gameState.references.players.set(playerSchema.clientId, {
    element: playerSpine,
    lastShootingTime: Date.now(),
    schema: playerSchema
  })

  playerSpine.zIndex = 12

  //setTimeout(() => {
  //  const graphic = new Graphics()
  //    .beginFill(0xff0000).drawRect(playerSchema.x, playerSchema.y, playerSchema.width, playerSchema.height).endFill()
  //    .beginFill(0x00ff00).drawRect(playerSchema.x + playerSchema.boundingX, playerSchema.y + playerSchema.boundingY, playerSchema.boundingWidth, playerSchema.boundingHeight).endFill()
  //  graphic.zIndex = 12
  //  gameState.viewport!.addChild(graphic)
  //}, 2000)

  const width = (playerSpine.width) * 0.8
  const height = 12
  const gap = 3

  const {container: healthContainer, bar: healthBar} = drawProgressBar(
    width,
    height,
    gap,
    0x111111,
    playerSchema.color === '#000000' ? 0x33ff33 : parseInt(playerSchema.color.replace(/^#/, ''), 16)
  )

  healthContainer.position.set(playerSpine.x - width / 2, playerSpine.y)
  healthContainer.zIndex = 12

  gameState.viewport.addChild(healthContainer)

  gameState.references.ui.level.healthBars.set(playerSchema.clientId, {
    container: healthContainer,
    bar: healthBar
  })

  if (playerSchema.clientId === gameState.level?.state.localPlayer.clientId) {
    const {container: shootingContainer, bar: shootingBar} = drawProgressBar(width, 9, gap, 0x111111, 0xffb266)

    shootingContainer.position.set(healthContainer.x, healthContainer.y + height - gap)
    shootingContainer.zIndex = 12

    gameState.viewport.addChild(shootingContainer)

    gameState.references.ui.level.shootingBars.set(playerSchema.clientId, {
      container: shootingContainer,
      bar: shootingBar
    })
  }

  // Username

  const style = new TextStyle({
    fontFamily: 'Supersonic Rocketship',
    fontWeight: '400',
    letterSpacing: 3,
    fill: 0xffffff,
    fontSize: Math.min(Math.max(12, 6 * getWidthRatio()), 14),
    dropShadow: true,
    dropShadowDistance: 2,
    dropShadowColor: 0x000000,
    dropShadowAlpha: 0.9,
    dropShadowAngle: 1.5,
    align: 'center',
    stroke: 0x000000,
    strokeThickness: 1
  })

  const username = new Text(playerSchema.name.slice(0, 12), style)

  const metrics = TextMetrics.measureText(username.text, username.style)

  username.style = style
  username.x = playerSpine.x - metrics.width / 2
  username.y = playerSpine.y - playerSpine.height - metrics.height
  username.alpha = 0.5

  username.zIndex = Number.MAX_SAFE_INTEGER - 10

  gameState.references.ui.level.usernames.set(playerSchema.clientId, username)

  // Pointer

  if (playerSchema.clientId !== gameState.level?.state.localPlayer.clientId) {
    const pointer = new Graphics()
      .beginFill(playerSchema.color)
      .lineStyle({
        width: Math.min(2, 1 * getWidthRatio()),
        color: 0x000000
      })
      .moveTo(-10, 10)
      .lineTo(10, 0)
      .lineTo(-10, -10)
      .closePath()
      .endFill()

    pointer.zIndex = Number.MAX_SAFE_INTEGER - 6

    gameState.references.ui.level.pointers.set(playerSchema.clientId, pointer)

    gameState.viewport.addChild(pointer)
  }

  // Shadow

  const shadow = new Graphics()
    .beginFill(0xffffff)
    .drawEllipse(0, 0, playerSpine.width * 1.4 * ratio, playerSpine.width * ratio * 1.4 / 4)
    .endFill()

  shadow.tint = playerSchema.color
  shadow.alpha = 0.4
  shadow.zIndex = 11

  shadow.position.set(playerSpine.x, playerSpine.y)

  gameState.references.ui.level.shadows.set(playerSchema.clientId, shadow)

  gameState.viewport.addChild(shadow)

  // GUN
  const gunSpine = new Spine(gameState.resources.gun.spineData)

  gunSpine.skeleton.setSkinByName(`GUN/${playerSchema.weapon.type.toUpperCase()}`)
  gunSpine.state.setAnimation(0, 'GUN_IDLE', true)

  switch (playerSchema.weapon.type) {
    case Weapon.MachineGun:
    case Weapon.CommonBlaster:
    case Weapon.EpicBlaster:
    case Weapon.FabledBlaster:
    case Weapon.GanAutoRiffle:
    case Weapon.Shotgun:
      gunSpine.pivot.y -= 67.7
      break
    case Weapon.XMAS23:
      gunSpine.pivot.y -= 80
      break
    case Weapon.Carrot:
      gunSpine.pivot.y -= 90
      break
    case Weapon.Flamethrower:
      gunSpine.pivot.y -= 50
      break
    case Weapon.NinjaStar:
      gunSpine.pivot.x = 0
      gunSpine.pivot.y = 0
      break
  }

  if (playerSchema.weapon.type === Weapon.Flamethrower) {
    const particlesContainer = new Container()

    particlesContainer.zIndex = 95

    gameState.viewport.addChild(particlesContainer)

    const emitter = fireParticles(
      particlesContainer,
      0,
      50,
      [gameState.resources.fireParticle, gameState.resources.circleParticle]
    )

    emitter.rotate(-Math.PI / 2 - 0.1)

    emitter.playOnceAndDestroy()

    gameState.references.particles.set(playerSchema.clientId, {
      container: particlesContainer,
      emitter
    })
  }

  gunSpine.position.set(playerSpine.x, playerSpine.y)
  gunSpine.scale.set(playerSpine.scale.x, playerSpine.scale.y)

  gunSpine.zIndex = 13

  gameState.references.guns.set(playerSchema.clientId, gunSpine)

  gameState.viewport.addChild(playerSpine, gunSpine, username)

  if (playerSchema.clientId === gameState.level?.state.localPlayer.clientId) {
    gameState.viewport.follow(playerSpine)
  }

  return {
    playerSpine,
    gunSpine
  }
}

/**
 * Update the players position
 * @param delta The delta (time passed since previous update)
 */
export const updatePlayers = (delta: number) => {
  if (gameState.level?.state.ended) return

  gameState.references.players.forEach((player, clientId) => {
    if (!gameState.level) return

    const playerSpine = player?.element
    const gunSpine = gameState.references.guns.get(clientId)
    const username = gameState.references.ui.level.usernames.get(clientId)

    if (!playerSpine || !gunSpine || !username) return

    // Username
    if (player.schema.life <= 0 || player.schema.dead) {
      username.x = playerSpine.x - username.width / 2 - playerSpine.height * 0.4
      username.y = playerSpine.y - playerSpine.width / 2 - username.height
    } else {
      username.x = playerSpine.x - username.width / 2
      username.y = playerSpine.y - playerSpine.height - username.height
    }

    // POINTERS
    if (player.schema.clientId === gameState.level.state.localPlayer.clientId) {
      gameState.references.players.forEach((currentPlayer) => {
        if (!currentPlayer) return

        const pointer = gameState.references.ui.level.pointers.get(currentPlayer.schema.clientId)

        if (currentPlayer.schema.dead && pointer) {
          gameState.references.ui.level.pointers.delete(currentPlayer.schema.clientId)
          pointer.destroy()

          return
        }

        if (currentPlayer.schema.clientId === player.schema.clientId || !pointer) return

        const coords = {
          x: currentPlayer.element.x,
          y: currentPlayer.element.y
        }

        if (currentPlayer.schema.life <= 0) {
          coords.x -= playerSpine.height * 0.4
          coords.y += playerSpine.height / 2
        }

        const {dx, dy} = computeVelocityToReach(coords, player.element)

        const angle = segmentAngle(0, 0, dx, dy)
        pointer.rotation = angle

        pointer.position.set(player.element.x + 75 * dx, player.element.y + 75 * dy - player.element.height / 2)
      })
    }

    //Shadow
    updateShadow(clientId)

    if (player.schema.life <= 0 || player.schema.dead) return

    const {
      x,
      y
    } = playerSpine

    let {
      x: newX,
      y: newY,
    } = player.schema

    const {
      width,
      dx,
      dy,
      speed
    } = player.schema

    newX += width / 2
    newY += playerSpine.height

    let computedX = x + dx * speed * delta
    let computedY = y + dy * speed * delta

    const differenceX = newX - computedX
    const differenceY = newY - computedY

    computedX += differenceX * delta * 0.02
    computedY += differenceY * delta * 0.02

    if (Math.abs(differenceX) > 100 || Math.abs(differenceX) < 0.02) {
      computedX = newX
    }

    if (Math.abs(differenceY) > 100 || Math.abs(differenceY) < 0.02) {
      computedY = newY
    }

    playerSpine.zIndex = 30 + Math.floor(computedY)

    // PLAYER
    playerSpine.position.set(
      computedX,
      computedY
    )

    const widthBar = (playerSpine.width) * 0.8
    const heightBar = 12
    const gapBar = 3

    gameState.references.ui.level.healthBars.get(player.schema.clientId)?.container.position.set(computedX - widthBar / 2, computedY + 10)
    gameState.references.ui.level.shootingBars.get(player.schema.clientId)?.container.position.set(computedX - widthBar / 2, computedY + heightBar - gapBar + 10)

    // GUN 
    gunSpine.position.set(playerSpine.x, playerSpine.y - 0.25 * player.element.height)
    gunSpine.zIndex = playerSpine.zIndex + 1

    // WAITING DOTS
    const dots = gameState.references.ui.level.waitingDots.get(player.schema.clientId)

    if (dots) {
      dots.position.set(player.element.x - dots.width / 2, player.element.y - player.element.height - 35)
    }
  })
}

export const updateShadow = (clientId: string) => {
  const player = gameState.references.players.get(clientId)
  const shadow = gameState.references.ui.level.shadows.get(clientId)

  if (!player || !shadow) return

  if (player.schema.life <= 0 || player.schema.dead) {
    shadow.position.set(player.element.x - player.element.height * 0.4, player.element.y + player.element.height / 2 - shadow.height / 2)
    shadow.scale.set(1.5, 1.5)
    shadow.tint = 0xEC2727
  } else {
    shadow.position.set(player.element.x, player.element.y)
    shadow.scale.set(1, 1)
    shadow.tint = player.schema.color
  }
}