@import "scss-variables";

.leaderboard-tab {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  overflow-y: auto;
  min-height: 200px;

  .entry {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    //box-sizing: border-box;
    //background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input-test.svg);
    //background-repeat: no-repeat;
    //background-size: 100% 100%;
    border-radius: 0;
    padding: 1rem 1rem 0.6rem 1rem;

    > div {
      z-index: 1;
    }

    &.my {
      .bg {
        opacity: 1;
      }
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      margin: auto;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg);
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.4;
    }

    .rank {
      color: white;
      background-color: transparent;
      background-position: center;
      border-radius: 0;
      background-size: contain;
      background-repeat: no-repeat;

      &.first {
        color: #ffe500;
      }

      &.second {
        color: #ebebeb;
      }

      &.third {
        color: #be6c61;
      }
    }

    .club {
      width: 1rem;
      text-align: center;

      img {
        height: 1.25rem;
      }
    }

    .violet {
      color: #db43a3;
    }

    .key {
      flex: 1;
    }
  }

  .list-container {
    display: flex;
    flex: 1;
    overflow-y: auto;
    position: relative;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      width: 100%;
      height: fit-content;

      .loader {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 24px;

        width: 100%;
        height: 100%;

        grid-column: 2;

        color: white;
      }
    }
  }

  .info {
    font-size: 12px;
    text-align: center;
    color: #666;
  }
}
