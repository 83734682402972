import {sound} from '@pixi/sound'
import Decimal from 'decimal.js'
import FontFaceObserver from 'fontfaceobserver'
import gsap from 'gsap'
import {Viewport} from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import {
  clearListeners, clearReferences, updateCountdowns
} from './level'
import {exitRoom} from './server/server-handler'
import {loadSounds} from './sound'
import {gameState, initGameState} from './state'
import {State} from './types'
import {getGameHeight, getGameWidth} from './utils'
import {OOGY_ANIM_CDN_URL, OOGY_VISUAL_CDN_URL} from './utils/constants'

import '@pixi-spine/loader-uni'
import {updateAuras} from './aura'
import {updateBullets} from './bullet'
import {updateItems} from './item'
import {updateMonsters} from './monster'
import {updatePlayers} from './player'
import {updateSkills} from './skill'

/**
 * Initialize the game into a canvas DOM object
 */
export const initGame = async (
  canvas: HTMLCanvasElement | undefined,
  config: State['external'],
  onLoaderProgress?: (progress: number, exited?: boolean) => void
) => {
  if (!canvas) return

  gameState.external = {...config}
  gameState.exited = false

  //PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR
  PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL2
  PIXI.Graphics.curves.maxLength = 3

  const parent = canvas.parentElement!

  const app = new PIXI.Application({
    view: canvas,
    resolution: Math.max(2, window.devicePixelRatio),
    autoDensity: true,
    antialias: false,
    powerPreference: 'high-performance',
    clearBeforeRender: false,
    resizeTo: parent,
    backgroundAlpha: 0
  })

  const defaultIcon = `url('${OOGY_VISUAL_CDN_URL}/ui/cursor.png') 16 22, auto`
  app.renderer.events.cursorStyles.default = defaultIcon

  gameState.app = app

  app.stage.eventMode = 'static'

  app.ticker.stop()

  const loader = PIXI.Assets

  const gsapTicker = () => {
    if (gameState.exited) return

    app.ticker.update()
  }

  // Now, we use 'tick' from gsap
  gsap.ticker.add(gsapTicker)

  gameState.external.clearGame = async () => {
    console.log('CLEAR GAME')

    gameState.exited = true

    gsap.ticker.sleep()

    clearListeners()
    clearReferences()

    app.renderer.clear()
    app.stage.removeChildren()

    gameState.viewport?.destroy()

    sound.stopAll()

    if (gameState.room) {
      await exitRoom()
    }

    initGameState()

    if (config.clearGame) config.clearGame()
  }

  const ceesHand = new FontFaceObserver('Cees Hand')
  const moreSugar = new FontFaceObserver('More Sugar')
  const supersonicRocketship = new FontFaceObserver('Supersonic Rocketship')

  loader.addBundle('oogy', {
    // GLOBAL
    logo: `${OOGY_VISUAL_CDN_URL}/ui/logo.png`,
    // UI
    trophy: `${OOGY_VISUAL_CDN_URL}/ui/trophy.png`,
    gem: `${OOGY_VISUAL_CDN_URL}/ui/gem.png`,
    'white-oogy': `${OOGY_VISUAL_CDN_URL}/ui/white-oogy.png`,
    'star-light': `${OOGY_VISUAL_CDN_URL}/ui/star-light.png`,
    // LEVEL
    //'level-bg': `${OOGY_VISUAL_CDN_URL}/level/bg.png`,
    'level-bg-sand': `${OOGY_VISUAL_CDN_URL}/level/bg/sand.jpg`,
    'level-bg-zombie': `${OOGY_VISUAL_CDN_URL}/level/bg/zombie.jpg`,
    'level-bg-clay': `${OOGY_VISUAL_CDN_URL}/level/bg/clay.jpg`,
    'level-bg-crop': `${OOGY_VISUAL_CDN_URL}/level/bg/crop.jpg`,
    'level-bg-field': `${OOGY_VISUAL_CDN_URL}/level/bg/field.jpg`,
    'level-bg-forest': `${OOGY_VISUAL_CDN_URL}/level/bg/forest.jpg`,
    'level-bg-lava': `${OOGY_VISUAL_CDN_URL}/level/bg/lava.jpg`,
    'level-bg-polar': `${OOGY_VISUAL_CDN_URL}/level/bg/polar.jpg`,
    'level-bg-temple': `${OOGY_VISUAL_CDN_URL}/level/bg/temple.jpg`,
    'level-bg-ore': `${OOGY_VISUAL_CDN_URL}/level/bg/ore.jpg`,
    'level-bg-goo': `${OOGY_VISUAL_CDN_URL}/level/bg/goo.jpg`,
    'level-bg-dirt': `${OOGY_VISUAL_CDN_URL}/level/bg/dirt.jpg`,
    'level-bg-bricks': `${OOGY_VISUAL_CDN_URL}/level/bg/bricks.jpg`,
    'level-bg-plates': `${OOGY_VISUAL_CDN_URL}/level/bg/plates.jpg`,
    'level-bg-lab': `${OOGY_VISUAL_CDN_URL}/level/bg/lab.jpg`,
    'level-bg-street': `${OOGY_VISUAL_CDN_URL}/level/bg/street.jpg`,
    'level-bg-junkyard': `${OOGY_VISUAL_CDN_URL}/level/bg/junkyard.jpg`,
    'level-bg-blocks': `${OOGY_VISUAL_CDN_URL}/level/bg/blocks.jpg`,
    'level-bg-soil': `${OOGY_VISUAL_CDN_URL}/level/bg/soil.jpg`,
    'level-bg-swamp': `${OOGY_VISUAL_CDN_URL}/level/bg/swamp.jpg`,
    'level-bg-mars': `${OOGY_VISUAL_CDN_URL}/level/bg/mars.jpg`,
    'level-bg-moon': `${OOGY_VISUAL_CDN_URL}/level/bg/moon.jpg`,
    'level-bg-meltdown': `${OOGY_VISUAL_CDN_URL}/level/bg/meltdown.jpg`,
    'level-bg-metal_floor': `${OOGY_VISUAL_CDN_URL}/level/bg/metal_floor.jpg`,
    'level-bg-power_cell': `${OOGY_VISUAL_CDN_URL}/level/bg/power_cell.jpg`,
    menu: `${OOGY_VISUAL_CDN_URL}/level/menu-button.png`,
    skull: `${OOGY_VISUAL_CDN_URL}/level/skull.png`,
    coin: `${OOGY_VISUAL_CDN_URL}/level/coin.png`,
    clock: `${OOGY_VISUAL_CDN_URL}/level/clock.png`,
    monster: `${OOGY_VISUAL_CDN_URL}/level/monster.png`,
    icecube: `${OOGY_VISUAL_CDN_URL}/level/icecube.png`,
    'clock-container': `${OOGY_VISUAL_CDN_URL}/level/clock-container.png`,
    'score-container': `${OOGY_VISUAL_CDN_URL}/level/score-container.png`,
    'level-progress': `${OOGY_VISUAL_CDN_URL}/level/level-progress.png`,
    //'level-bars': `${OOGY_VISUAL_CDN_URL}/level/level-bars.png`,
    'level-bar-bg': `${OOGY_VISUAL_CDN_URL}/level/level-bar-bg.jpg`,
    'level-flask': `${OOGY_VISUAL_CDN_URL}/level/level-flask.png`,
    star: `${OOGY_VISUAL_CDN_URL}/level/star.png`,
    'item-small-xp': `${OOGY_VISUAL_CDN_URL}/level/small-xp.png`,
    'item-big-xp': `${OOGY_VISUAL_CDN_URL}/level/big-xp.png`,
    'item-heal': `${OOGY_VISUAL_CDN_URL}/level/heal.png`,
    'item-shield': `${OOGY_VISUAL_CDN_URL}/level/shield.png`,
    'item-bomb': `${OOGY_VISUAL_CDN_URL}/level/bomb.png`,
    'item-gem': `${OOGY_VISUAL_CDN_URL}/ui/gem.png`,
    'blue-bullet': `${OOGY_VISUAL_CDN_URL}/level/bullets/blue.png`,
    'yellow-bullet': `${OOGY_VISUAL_CDN_URL}/level/bullets/yellow.png`,
    'pink-bullet': `${OOGY_VISUAL_CDN_URL}/level/bullets/pink.png`,
    'red-bullet': `${OOGY_VISUAL_CDN_URL}/level/bullets/red.png`,
    'card-bullet': `${OOGY_VISUAL_CDN_URL}/level/bullets/card.png`,
    'slime-bullet': `${OOGY_VISUAL_CDN_URL}/level/bullets/slime.png`,
    'xmas-bullet': `${OOGY_VISUAL_CDN_URL}/level/bullets/xmas.png`,
    'carrot-bullet': `${OOGY_VISUAL_CDN_URL}/level/bullets/carrot.png`,
    // SKILLS
    'skills-title': `${OOGY_VISUAL_CDN_URL}/ui/skills/title.png`,
    'skills-star': `${OOGY_VISUAL_CDN_URL}/ui/skills/star.png`,
    'skills-star-full': `${OOGY_VISUAL_CDN_URL}/ui/skills/star-full.png`,
    'skills-star-empty': `${OOGY_VISUAL_CDN_URL}/ui/skills/star-empty.png`,
    'skills-defensive': `${OOGY_VISUAL_CDN_URL}/ui/skills/defensive.png`,
    'skills-healing-drone': `${OOGY_VISUAL_CDN_URL}/ui/skills/healing-drone.png`,
    'skills-rocket-drone': `${OOGY_VISUAL_CDN_URL}/ui/skills/rocket-drone.png`,
    'skills-lightning': `${OOGY_VISUAL_CDN_URL}/ui/skills/lightning.png`,
    // SPINE
    player: `${OOGY_ANIM_CDN_URL}/player/player.json`,
    gun: `${OOGY_ANIM_CDN_URL}/player/gun.json`,
    bat: `${OOGY_ANIM_CDN_URL}/monsters-static/bat.json`,
    flying_deer: `${OOGY_ANIM_CDN_URL}/monsters-static/flying_deer.json`,
    ghost: `${OOGY_ANIM_CDN_URL}/monsters-static/ghost.json`,
    ogre: `${OOGY_ANIM_CDN_URL}/monsters-static/ogre.json`,
    rainbow: `${OOGY_ANIM_CDN_URL}/monsters-static/rainbow.json`,
    spider: `${OOGY_ANIM_CDN_URL}/monsters-static/spider.json`,
    blue_monster_1: `${OOGY_ANIM_CDN_URL}/monsters-static/blue_monster_1.json`,
    blue_monster_2: `${OOGY_ANIM_CDN_URL}/monsters-static/blue_monster_2.json`,
    blue_monster_3: `${OOGY_ANIM_CDN_URL}/monsters-static/blue_monster_3.json`,
    pink_monster_1: `${OOGY_ANIM_CDN_URL}/monsters-static/pink_monster_1.json`,
    pink_monster_2: `${OOGY_ANIM_CDN_URL}/monsters-static/pink_monster_2.json`,
    pink_monster_3: `${OOGY_ANIM_CDN_URL}/monsters-static/pink_monster_3.json`,
    red_monster_1: `${OOGY_ANIM_CDN_URL}/monsters-static/red_monster_1.json`,
    red_monster_2: `${OOGY_ANIM_CDN_URL}/monsters-static/red_monster_2.json`,
    crier: `${OOGY_ANIM_CDN_URL}/monsters-static/crier.json`,
    klaus: `${OOGY_ANIM_CDN_URL}/monsters-static/klaus.json`,
    'item-mystery-box': `${OOGY_ANIM_CDN_URL}/chests/mystery-box.json`,
    'chests': `${OOGY_ANIM_CDN_URL}/chests/chests.json`,
    'heal-aura': `${OOGY_ANIM_CDN_URL}/auras/heal.json`,
    'power-aura': `${OOGY_ANIM_CDN_URL}/auras/power.json`,
    'lightning-effect': `${OOGY_ANIM_CDN_URL}/effects/lightning.json`,
    'rocket-drone': `${OOGY_ANIM_CDN_URL}/drones/drone-blue.json`,
    'healing-drone': `${OOGY_ANIM_CDN_URL}/drones/drone-red.json`,
    'rocket-bullet': `${OOGY_ANIM_CDN_URL}/rocket/rocket.json`,
    'rocket-explosion': `${OOGY_ANIM_CDN_URL}/rocket/rocket-explosion.json`,
    'electricity-aoe': `${OOGY_ANIM_CDN_URL}/auras/defensive.json`,
    // Bosses
    'boss_1': `${OOGY_ANIM_CDN_URL}/bosses/boss_1/boss_1.json`,
    'boss_1-bullet': `${OOGY_ANIM_CDN_URL}/bosses/boss_1/boss_1_bullet.json`,
    'boss_1_lightning': `${OOGY_ANIM_CDN_URL}/bosses/boss_1/boss_1_lightning.json`,
    'boss_2': `${OOGY_ANIM_CDN_URL}/bosses/boss_2/boss_2.json`,
    'boss_2-bullet': `${OOGY_ANIM_CDN_URL}/bosses/boss_2/boss_2_bullet.png`,
    'yeti': `${OOGY_ANIM_CDN_URL}/bosses/yeti/YETI.json`,
    'yeti-bullet': `${OOGY_ANIM_CDN_URL}/bosses/yeti/ICEBALL.png`,
    'yeti-spike': `${OOGY_ANIM_CDN_URL}/bosses/yeti/SPIKES.json`,
    'yeti-icewave': `${OOGY_ANIM_CDN_URL}/bosses/yeti/ICE_WAVE.json`,
    // Particles
    fireParticle: `${OOGY_VISUAL_CDN_URL}/particles/fire.png`,
    circleParticle: `${OOGY_VISUAL_CDN_URL}/particles/circle.png`
  })

  let resources

  try {
    if (onLoaderProgress) {
      onLoaderProgress(0)

      let soundProgress = 0
      let loaderProgress = 0

      const updateProgress = (newSoundProgress?: number, newLoaderProgress?: number) => {
        if (newSoundProgress !== undefined) {
          soundProgress = newSoundProgress
        }

        if (newLoaderProgress !== undefined) {
          loaderProgress = newLoaderProgress
        }

        onLoaderProgress(soundProgress * 0.5 + loaderProgress * 0.5, gameState.exited)
      }

      loadSounds((progress) => {
        updateProgress(progress)
      }, () => {
        updateProgress(100)
      })

      resources = await loader.loadBundle('oogy', (progress) => updateProgress(undefined, progress))

      updateProgress(undefined, 100)
    } else {
      loadSounds()
      resources = await loader.loadBundle('oogy')
    }
  } catch (err) {
    console.error(err)

    if (!gameState.exited) {
      gameState.exited = true

      gameState.external.clearGame()

      gameState.external.showLoading(false)

      gameState.external.showModal({
        title: 'Error',
        text: 'Impossible to load the graphic resources, please check your internet connection and refresh the page'
      })
    }

    return
  }

  gameState.resources = resources

  Promise.all([
    ceesHand.load(),
    moreSugar.load(),
    supersonicRocketship.load()
  ]).then(() => {
    if (onLoaderProgress) onLoaderProgress(100)

    app.resize()


    app.ticker.minFPS = 60
    app.ticker.add((delta) => {
      const roundedDelta = new Decimal(delta).mul(10).round().div(10).toNumber()

      update(roundedDelta)
    })
  }).catch((err) => {
    console.error('loading failed', err)
  })

  return gameState.external.clearGame
}

export const createViewport = (clamp?: boolean, worldWidth?: number, worldHeight?: number) => {
  if (!gameState.app || !gameState.resources) return

  if (gameState.viewport) {
    try {
      gameState.viewport.destroy()
    } catch (err) {
      console.error(err)
    }
  }

  const viewport = new Viewport({
    screenWidth: getGameWidth(),
    screenHeight: getGameHeight(),
    worldWidth,
    worldHeight,
    events: gameState.app.renderer.events
  })

  if (clamp) {
    viewport.clamp({
      direction: 'all',
      underflow: 'center'
    })
  }

  gameState.app.renderer.on('resize', () => {
    //if (gameState.external.clearGame) {
    //  gameState.external.clearGame()
    //}

    gameState.external.showModal({
      title: 'Do not change the size of the game',
      text: 'Changing the size of the game can create visual bugs. Please, put the size back to previous one or refresh the page with the desired size.'
    })

    //viewport.resize(getGameWidth(), getGameHeight())
  })

  viewport.sortableChildren = true
  viewport.eventMode = 'static'
  viewport.interactiveChildren = false

  gameState.app.stage.addChild(viewport)

  gameState.viewport = viewport

  // addWaterEffect(state.app.stage, new Sprite(state.resources.waterDisplacement))

  // addShockwaveEffect(viewport, new Point(getGameWidth() / 2, getGameHeight() / 2), undefined, {duration: 5})
}

/**
 * Update the state of the game
 * @param delta The delta (time passed since previous update)
 */
const update = (delta: number) => {
  //if (gameState.level?.state.paused) return
  if (!gameState.level || gameState.level.state.ended) return

  updateEmitters()

  updateBullets(delta)
  updateItems(delta)
  updatePlayers(delta)
  updateAuras(delta)
  updateSkills(delta)
  updateMonsters(delta)

  updateCountdowns()
}

const updateEmitters = () => {
  for (const [clientId, value] of gameState.references.particles) {
    const playerSpine = gameState.references.players.get(clientId)
    const gunSpine = gameState.references.guns.get(clientId)

    if (value.container && value.emitter && playerSpine && gunSpine) {
      value.container.x = playerSpine.element.x
      value.container.y = playerSpine.element.y - 30
    }
  }
}