import CssFilterConverter from 'css-filter-converter'
import { Weapon } from 'oogy-blast/src/enums'
import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { useState } from 'react'
import { formatClassName } from '../../../../../../../../utils/global'
import { buyWeaponRequest } from '../../../../../../../api/hub'
import { Button } from '../../../../../../../components/buttons/button'
import { GenericModal, GenericModalProps } from '../../../../../../../components/modal/modal'
import { getWeaponsData } from '../../../../../../../data/weapons'
import { useHubContext } from '../../../../../../../state/context'
import { hubState } from '../../../../../../../state/hub'
import styles from './loadout.module.scss'

export type LoadoutProps = GenericModalProps & {
  onSelect: (loadout: number) => void
}

const Content = ({ onSelect: onSelectCallback }: {
  onSelect: (loadout: number) => void
}) => {
  const { state: { accountInfo } } = useHubContext()
  const [loading, setLoading] = useState<Weapon>()

  const onBuy = async (weaponName: Weapon) => {
    const data = await getWeaponsData()

    const entry = data[weaponName]

    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Buy weapon',
        text: `Do you really want to buy the weapon for ${entry.levels[0].priceToUnlock.gems} gems?`,
        acceptText: 'Yes',
        refuseText: 'No',
        async onAccept() {
          setLoading(weaponName)

          const result = await buyWeaponRequest(weaponName)

          if (result?.data && hubState.refreshAccountInfo) {
            hubState.refreshAccountInfo()
          }

          setLoading(undefined)
        }
      })
    }
  }

  const onSelect = (loadout: number) => {
    let weapon: Weapon = Weapon.CommonBlaster

    switch (loadout) {
      case 0:
        weapon = Weapon.CommonBlaster
        break
      case 1:
        weapon = Weapon.MachineGun
        break
      case 2:
        weapon = Weapon.GanAutoRifle
        break
    }

    console.log(weapon)

    if (accountInfo?.character.weapons.unlocked[weapon] === undefined) {
      onBuy(weapon)
    } else {
      onSelectCallback(loadout)
    }
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'list')}>
      <div
        className={formatClassName(styles, 'tile')}
        onClick={() => onSelect(0)}
      >
        <div
          className={formatClassName(styles, 'bg')}
          style={{
            filter: `brightness(0) saturate(100%) ${CssFilterConverter.hexToFilter('#FFAC9E').color}` ?? 'none',
          }}
        ></div>
        <div
          className={formatClassName(styles, 'image')}
          style={{
            backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/weapons/common_blaster.png')`,
          }}
        ></div>
        {
          accountInfo?.character.weapons.unlocked['COMMON_BLASTER'] === undefined && <div className={formatClassName(styles, 'buy')}>
            <Button onClick={() => onBuy(Weapon.CommonBlaster)} loading={loading === Weapon.CommonBlaster}>Buy</Button>
          </div>
        }
      </div>
      <div
        className={formatClassName(styles, 'tile')}
        onClick={() => onSelect(1)}
      >
        <div
          className={formatClassName(styles, 'bg')}
          style={{
            filter: `brightness(0) saturate(100%) ${CssFilterConverter.hexToFilter('#83FBFF').color}` ?? 'none',
          }}
        ></div>
        <div
          className={formatClassName(styles, 'image')}
          style={{
            backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/weapons/machine_gun.png')`,
          }}
        ></div>
        {
          accountInfo?.character.weapons.unlocked['MACHINE_GUN'] === undefined && <div className={formatClassName(styles, 'buy')}>
            <Button onClick={() => onBuy(Weapon.MachineGun)} loading={loading === Weapon.MachineGun}>Buy</Button>
          </div>
        }
      </div>
      <div
        className={formatClassName(styles, 'tile')}
        onClick={() => onSelect(2)}
      >
        <div
          className={formatClassName(styles, 'bg')}
          style={{
            filter: `brightness(0) saturate(100%) ${CssFilterConverter.hexToFilter('#ffe374').color}` ?? 'none',
          }}
        ></div>
        <div
          className={formatClassName(styles, 'image')}
          style={{
            backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/weapons/gan_auto_rifle.png')`,
          }}
        ></div>
        {
          accountInfo?.character.weapons.unlocked['GAN_AUTO_RIFLE'] === undefined && <div className={formatClassName(styles, 'buy')}>
            <Button onClick={() => onBuy(Weapon.GanAutoRifle)} loading={loading === Weapon.GanAutoRifle}>Buy</Button>
          </div>
        }
      </div>
    </div>
    <div className={formatClassName(styles, 'list info')}>
      <div>Blaster</div>
      <div>Machine Gun</div>
      <div>Gan Auto Rifle</div>
    </div>
    <div className={formatClassName(styles, 'list details')}>
      <div>
        <div>- Slow fire</div>
        <div>- Mid range</div>
        <div>- Low damages</div>
      </div>
      <div>
        <div>- Rapid fire</div>
        <div>- Short range</div>
        <div>- Low damages</div>
      </div>
      <div>
        <div>- Rapid fire</div>
        <div>- Long range</div>
        <div>- High damages</div>
      </div>
    </div>
  </div>
}

const Title = ({ onClose }: { onClose: () => void }) =>
  <div className={formatClassName(styles, 'title')}>
    Select your loadout
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const Loadout = ({ className, onClose, onSelect, show, ...props }: LoadoutProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `loadout ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content onSelect={onSelect} />}
      onClickBackground={onClose}
      show={show}
      {...props}
    />
  )
}